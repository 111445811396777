import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useFetch from '@/hooks/useFetch';
import { Spinner } from '@nextui-org/react';
import AuthContext from '@/auth/context';

export default function Content({ item, activeGlowId, setActiveGlowId }: any) {
  const [pageRequest, setPageRequest] = useState<any>({
    status: item.status,
    title: item.data.title,
    thumbnailURL: item.data.thumbnail_url,
    data: null,
  });
  const navigate = useNavigate();
  const { prompt } = item.data.input_params;
  const date = item.created_at;
  const [progress, setProgress] = useState(0);
  const authContext = useContext(AuthContext);
  const [regeneratedAt, setRegeneratedAt] = useState<Date | null>(null);

  const { fetchData: getPage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${item.content_id}/`,
    { method: 'GET' },
  );

  const onClick = () => {
    navigate(`/pages/${item.page_id}`);
  };

  const formattedDate = `${new Date(date).toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  })}, ${new Date(date).toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  })}`;

  const fetchPage = async () => {
    try {
      const responseData = await getPage();
      if (responseData?.data?.status === 'completed' || responseData?.data?.status === 'failed') {
        if (progress < 100) {
          setProgress(100);
          setTimeout(() => {
            setPageRequest((prevState: any) => ({
              ...prevState,
              status: responseData.data.status,
              title: responseData.data.output?.title || prevState.title,
              thumbnailURL: responseData.data.output?.thumbnail_url || prevState.thumbnailURL,
              data: responseData.data.output || prevState.data,
            }));
          }, 1000);
        } else {
          setPageRequest((prevState: any) => ({
            ...prevState,
            status: responseData.data.status,
            title: responseData.data.output?.title || prevState.title,
            thumbnailURL: responseData.data.output?.thumbnail_url || prevState.thumbnailURL,
            data: responseData.data.output || prevState.data,
          }));
        }
        setActiveGlowId(item.content_id);
      } else {
        setTimeout(() => {
          fetchPage();
        }, 10000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!pageRequest.data && (pageRequest.status === 'processing' || pageRequest.status === 'pending')) {
      fetchPage().then(() => { });
    }
  }, [pageRequest]);

  useEffect(() => {
    if (pageRequest.status === 'processing' || pageRequest.status === 'pending') {
      const createdAt = regeneratedAt || new Date(date);
      const interval = setInterval(() => {
        const now = new Date();
        const diffInSeconds = Math.floor((now.getTime() - createdAt.getTime()) / 2000);
        const newProgress = Math.min(diffInSeconds, 99);
        setProgress(newProgress);
        if (newProgress >= 99) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [date, pageRequest.status]);

  const regenerateHandler = async () => {
    const token = await authContext.currentUser?.getIdToken();

    const postResponse = await fetch(
      `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${item.content_id}/regenerate`,
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    ).then((res) => res.json());

    if (postResponse.data.status !== 'failed') {
      setPageRequest((prevState: any) => ({
        ...prevState,
        status: postResponse.data.status,
      }));

      if (postResponse.data.regenerated_at) {
        setRegeneratedAt(new Date(postResponse.data.regenerated_at));
      }
    }
  };

  if (pageRequest.status === 'completed') {
    return (
      <div
        onClick={onClick}
        className="flex flex-col gap-2 xsm:w-72 sm:w-80 w-96 bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-2xl p-4 cursor-pointer"
      >
        <img
          className={`h-52 ${!pageRequest.thumbnailURL && 'dark:border dark:border-tertiary-dark'} self-center text-lg rounded-xl`}
          src={pageRequest.thumbnailURL || '/temporary-video-thumbnail.png'}
          alt=""
        />
        {pageRequest.title && <h3 className="font-medium truncate mt-6">{pageRequest.title || pageRequest.title}</h3>}
        {prompt
          && (
            <p className="truncate">
              <strong className="!font-medium text-[#787878] mr-1">Prompt:</strong>
              {prompt}
            </p>
          )}
        {date && <p className="text-sm truncate text-[#979A9D]">{formattedDate}</p>}
      </div>
    );
  }

  if (pageRequest.status === 'failed') {
    return (
      <div
        className={`${activeGlowId === item.content_id && "border border-[#FF953D]"} flex flex-col justify-center gap-4 xsm:w-72 sm:w-80 w-96 bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-2xl p-4`}
      >
        <h3
          onClick={onClick}
          className="font-medium cursor-pointer flex-col text-3xl h-52 flex text-center gap-4 truncate items-center justify-center flex-grow"
        >
          Failed
        </h3>
        {prompt && (
          <p className="truncate">
            <strong className="!font-medium text-[#787878] mr-1">Prompt:</strong>
            {prompt}
          </p>
        )}
        <div className="flex justify-between items-center">
          {date && (
            <p className="text-sm truncate text-[#979A9D]">
              {formattedDate}
            </p>
          )}
          <button
            onClick={regenerateHandler}
            className="text-sm dark:text-secondary-dark text-primary-light px-3 py-1.5 bg-[#ee822a] dark:bg-[#373839] w-fit rounded-lx"
          >
            Regenerate
          </button>
        </div>
      </div>
    );
  }

  if (pageRequest.status === 'processing' || pageRequest.status === 'pending') {
    return (
      <div onClick={onClick}
        className="flex flex-col justify-center gap-4 xsm:w-72 sm:w-80 w-96 bg-sectionBackground-light dark:bg-sectionBackground-dark rounded-2xl p-4 cursor-pointer"
      >
        <h3
          className="font-medium flex-col text-2xl h-52 flex text-center gap-4 truncate items-center justify-center flex-grow"
        >
          <div>
            <Spinner color="current" />
            <br />
            Generating
            {progress > 0 && ` ${progress}%`}
          </div>
        </h3>
        {prompt && (
          <p className="truncate">
            <strong className="!font-medium text-[#787878] mr-1">Prompt:</strong>
            {prompt}
          </p>
        )}
        {date && <p className="text-sm truncate text-[#979A9D]">{formattedDate}</p>}
      </div>
    );
  }
}

import { Spinner } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { prefetch } from 'remotion';

import useFetch from '@/hooks/useFetch';
import { getMediaLinks } from '@/utils/common';
import Video from './video';
import Podcast from './podcast';
import Blog from './blog';
import PodcastNew from './podcast-new';

interface ContentRequestState {
  loading: boolean;
  loaded: boolean;
  loadingFailed: boolean;
  data: any;
  responseData: any;
}

function SingleContent({ contentId, contentType = 'VIDEO' }: { contentId: string, contentType: string }) {
  const [contentRequest, setContentRequest] = useState<ContentRequestState>({
    loading: true,
    loaded: false,
    loadingFailed: false,
    data: null,
    responseData: null,
  });
  const [isMediaCached, setIsMediaCached] = useState(false);
  const [progress, setProgress] = useState(0);
  const [regeneratedAt, setRegeneratedAt] = useState<Date | null>(null);

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${contentId}/`,
    { method: 'GET' },
  );

  const fetchContent = async () => {
    try {
      const responseData = await getContent();
      if (responseData.data.status === 'completed') {
        if (progress < 100) {
          setProgress(100);
          setTimeout(() => {
            setContentRequest((prevState) => ({
              ...prevState,
              loading: false,
              loaded: true,
              data: responseData.data,
            }));
          }, 1000);
        } else {
          setContentRequest((prevState) => ({
            ...prevState,
            loading: false,
            loaded: true,
            data: responseData.data,
          }));
        }
      } else if (responseData.data.status === 'failed') {
        if (progress < 100) {
          setProgress(100);
          setTimeout(() => {
            setContentRequest((prevState) => ({
              ...prevState,
              loading: false,
              loaded: false,
              loadingFailed: true,
              data: responseData.data,
            }));
          }, 1000);
        } else {
          setContentRequest((prevState) => ({
            ...prevState,
            loading: false,
            loaded: false,
            loadingFailed: true,
            data: responseData.data,
          }));
        }
      }
      else if (responseData.data.status === 'processing' && responseData.data.is_regenerated) {
        setContentRequest((prevState) => ({
          ...prevState,
          loading: false,
          loaded: false,
          loadingFailed: true,
          data: responseData.data,
        }));

        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
      else {
        setContentRequest((prevState) => ({
          ...prevState,
          data: responseData.data,
        }));

        if (responseData.data.regenerated_at) {
          setRegeneratedAt(new Date(responseData.data.regenerated_at));
        }

        setTimeout(() => {
          fetchContent();
        }, 10000);
      }
    } catch (err) {
      setContentRequest((prevState) => ({
        ...prevState,
        loading: false,
        loaded: false,
        loadingFailed: true,
        data: null,
      }));
    }
  };

  useEffect(() => {
    if (contentRequest.loading && !contentRequest.data) {
      fetchContent().then(() => {
      });
    } else if (contentRequest.data && contentRequest.loaded) {
      let mediaLinks: string[] = [];

      if (!contentRequest.data.output.download_links) {
        mediaLinks = getMediaLinks(contentRequest.data.output.video_data);
      }
      const uniqueMediaLinks = new Set(mediaLinks);

      const promises: any[] = [];

      uniqueMediaLinks.forEach((link) => {
        promises.push(prefetch(link).waitUntilDone());
      });
      Promise.allSettled(promises).then(() => {
        setIsMediaCached(true);
      });
    }
  }, [contentRequest]);

  useEffect(() => {
    if (!contentRequest.loaded && !contentRequest.loadingFailed && contentRequest.loading && contentRequest?.data?.created_at || regeneratedAt) {
      const createdAt = regeneratedAt || new Date(contentRequest?.data?.created_at);
      const interval = setInterval(() => {
        const now = new Date();
        const diffInSeconds = Math.floor((now.getTime() - createdAt.getTime()) / 2000);
        const newProgress = Math.min(diffInSeconds, 99);
        setProgress(newProgress);
        if (newProgress >= 99 || progress >= 99) {
          clearInterval(interval);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [contentRequest, regeneratedAt]);

  const handleRegenerate = (isRegenerating: boolean, data: any) => {
    if (isRegenerating) {
      if (data.regenerated_at) {
        setRegeneratedAt(new Date(data.regenerated_at));
      }
      setProgress(0);
      fetchContent();
    } else {
      setRegeneratedAt(null);
      setProgress(100);
    }
  };

  return (
    contentRequest.loaded && isMediaCached ? (
      contentType === 'VIDEO' ? (
        <Video
          contentId={contentId}
          videoData={contentRequest.data.output.video_data}
          responseData={contentRequest.data}
        />
      ) : contentType === 'PODCAST' ? (
        contentRequest.data.output.version === 'v2' ? (
          <PodcastNew
            contentId={contentId}
            data={contentRequest.data}
          />
        ) : (
          <Podcast
            contentId={contentId}
            data={contentRequest.data}
          />
        )
      ) : contentType === 'BLOGPOST' ? (
        <Blog
          contentId={contentId}
          data={contentRequest.data.output}
          responseData={contentRequest.data}
        />
      ) : null
    ) : !contentRequest.loading && contentRequest.loadingFailed ? (
      contentType === 'VIDEO' ? (
        <Video
          contentId={contentId}
          responseData={contentRequest}
          onRegenerate={handleRegenerate}
          progress={progress}
        />
      ) :
        <div className="h-full dark:text-secondary-dark text-secondary-light flex justify-center items-center">
          <div>
            Couldn't generate the content. Please try again.
          </div>
        </div>
    )
      : (contentRequest?.data?.status === "processing" && contentType === 'VIDEO') ?
        <div
          className="flex justify-center h-[calc(100vh-150px)] flex-col gap-5 dark:text-secondary-dark text-secondary-light items-center"
          style={{ minHeight: 'inherit' }}
        >
          <div className='w-fit flex flex-col gap-5 p-10 shadow-lg rounded-xl dark:border dark:border-borderColor-dark dark:bg-sectionBackground-dark'>

            <div className='text-2xl font-semibold dark:text-secondary-dark text-secondary-light'>

              Generating your video
            </div>
            <div className='flex items-center gap-3 text-lg'>
              <Spinner color="current" size="sm" />
              Processing...
            </div>
            <div className='w-96 bg-gray-200 rounded-full h-2.5 dark:bg-gray-500'>
              <div className='bg-secondary-light dark:bg-secondary-dark h-2.5 rounded-full' style={{ width: `${progress}%` }}></div>
            </div>
            <div className=''>
              {`${progress}%`} Complete
            </div>
          </div>
        </div>
        :
        <div
          className="flex justify-center h-[calc(100vh-150px)] flex-col gap-5 dark:text-secondary-dark text-secondary-light items-center"
          style={{ minHeight: 'inherit' }}
        >
          <Spinner color="current" size="lg" />
          Loading Content...
        </div>
  );
}

export default SingleContent;

import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Spacer } from '@nextui-org/react';
import { Icon } from '@iconify/react';
import AuthContext from '@/auth/context';
import useFetch from '@/hooks/useFetch';
import LicaPage from '@/components/LicaPage';

import styles from './index.module.css';

function ProductVideo() {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [prompt, setPrompt] = useState('');
  const [useGemini, setUseGemini] = useState<boolean>(false);
  const [selectedFile, setSelectedFile] = useState<File | null>(null); // State for a single file
  const [
    videoPreview,
    setVideoPreview,
  ] = useState<string | null>(null); // State for a single image preview
  const [isGenerating, setIsGenerating] = useState(false);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  useEffect(() => {
    triggerGetFetch();
  }, [getData]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null; // Only take the first file
    if (file && file.type.startsWith('video/')) {
      setSelectedFile(file);
      setVideoPreview(URL.createObjectURL(file));
    }
  };

  const handleRemoveImage = () => {
    setSelectedFile(null);
    setVideoPreview(null);
  };

  const getSignedUrl = async (file: any) => {
    const token = await authContext.currentUser?.getIdToken();
    const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/gcs-signed-url/`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        fileName: file.name,
      }),
    }).then((res) => res.json());

    return response.data.signed_url;
  };

  const handleSubmit = async () => {
    if (!selectedFile) return;

    setIsGenerating(true);

    const signedUrl = await getSignedUrl(selectedFile);
    const fileUploadResponse = await fetch(signedUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/octet-stream',
      },
      body: selectedFile,
    });
    if (!fileUploadResponse.ok) {
      setIsGenerating(false);
      return;
    }

    const data: any = {
      prompt,
      content_types: [{
        type: 'VIDEO',
        params: {
          pipeline: 'PRODUCT_VIDEO',
          video_url: signedUrl.split(['?'])[0],
          use_gemini: useGemini,
        },
      }],
    };

    try {
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));

      const token = await authContext.currentUser?.getIdToken();

      const postResponse = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }).then((res) => res.json());

      if (postResponse) {
        navigate('/videos');
      }
    } finally {
      setIsGenerating(false);
    }
  };

  async function checkIfExtensionInstalled(extensionId: string) {
    try {
      await fetch(`chrome-extension://${extensionId}/lica.png`);
      return true;
    } catch (e) {
      return false;
    }
  }

  const onRecordClick = () => {
    const EXTENSION_ID = 'nklmgidmpaifdhffpmpaokkdcabnhpdi';
    checkIfExtensionInstalled(EXTENSION_ID).then((isInstalled) => {
      if (isInstalled) {
        // handle failure
        window.chrome.runtime?.sendMessage(
          EXTENSION_ID,
          { action: 'start-screen-record' },
          (response: any) => {
            console.log(response);
          },
        );
      } else {
        // open extension store
      }
    });
  };

  return (
    <LicaPage>
      <div
        className="bg-sectionBackground-light dark:bg-transparent sm:px-5 py-6 px-10 rounded-3xl flex flex-col items-center justify-center m-auto max-w-[1280px]"
      >
        <Spacer y={3} />
        <div className={`${styles.yourStoryContainer}`}>
          <label className="pl-1 xsm:text-base text-lg font-medium">
            Upload the raw screen recording with voiceover
          </label>
          <div
            className="min-h-36 flex gap-2 items-center justify-center bg-primary-light dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light p-4 outline-none rounded-2xl mt-2 cursor-pointer"
            onClick={() => fileInputRef.current?.click()}
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files[0];
                if (file.type.startsWith('video/')) {
                  setSelectedFile(file);
                  setVideoPreview(URL.createObjectURL(file));
                }
              }
            }}
          >
            <Icon
              icon="solar:videocamera-add-linear"
              className="text-secondary opacity-70"
              style={{ fontSize: '40px' }}
            />
            <div className="flex gap-1.5 color-[#f3f3f3] font-bold opacity-70">
              <div>
                Upload or Drag & Drop
              </div>
              {/* <div>or</div> */}
              {/* <div */}
              {/*  className="underline cursor-pointer" */}
              {/*  onClick={onRecordClick} */}
              {/* > */}
              {/*  Record now */}
              {/* </div> */}
            </div>
          </div>
          <div>
            <input
              type="file"
              id="file-upload"
              className={styles.imageUploadInput}
              onChange={handleFileChange}
              ref={fileInputRef}
              accept=".mp4,.mov,.webm"
            />
          </div>
          {videoPreview && (
            <div className={styles.imagePreviewContainer}>
              <div className="relative">
                <video
                  src={videoPreview}
                  className={styles.imagePreview}
                />
                <button
                  type="button"
                  onClick={handleRemoveImage}
                  className={styles.removeButton}
                >
                  <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                </button>
              </div>
            </div>
          )}
        </div>
        <Spacer y={6} />
        <div className="w-full">
          <label className="pl-1 xsm:text-base text-lg font-medium">
            Prompt
          </label>
          <textarea
            value={prompt}
            className="resize-none rounded-[10px] w-full sm:text-sm sm:h-20 text-lg outline-none minimal-scrollbar mt-2 bg-primary-light sm:placeholder:text-sm dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light px-4 py-2 dark:text-secondary-dark text-secondary-light placeholder:text-[#475467] dark:placeholder:text-[#737373]"
            placeholder="Specify instructions about the audience this product demo is for, style and tone"
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>
        {authContext.currentUser?.email?.includes('@lica.world') && (
          <div className="flex items-center w-full mt-6">
            <input
              type="checkbox"
              id="gemini-checkbox"
              className={`w-5 h-5 cursor-pointer appearance-none rounded-[4px] focus:ring-secondary ${useGemini
                ? 'border-gray-300 dark:checked:bg-[#93C5FD] checked:bg-[#6E400A] invert-[1] dark:invert-0 checked:bg-center checked:bg-no-repeat checked:bg-[length:60%_50%]'
                : 'border dark:border-[#575757] border-[#d3d3d3] bg-primary-light dark:!bg-[#1C1C1C]'
              }`}
              style={{
                backgroundImage: useGemini
                  ? 'url(/icons/tick.svg)'
                  : 'none',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '60% 50%',
              }}
              checked={useGemini}
              onChange={(e) => setUseGemini(e.target.checked)}
            />
            <label
              htmlFor="gemini-checkbox"
              className="ml-2 dark:text-secondary-dark text-secondary-light cursor-pointer"
            >
              Use Gemini
            </label>
          </div>
        )}
        <Button
          color="primary"
          className="px-4 py-6 mt-10 rounded-xl text-base font-medium text-primary-light dark:text-black hover:dark:bg-secondary-dark bg-tertiary-light dark:bg-secondary-dark"
          onClick={handleSubmit}
          isDisabled={!selectedFile}
          isLoading={isGenerating}
        >
          Generate Video
        </Button>
      </div>
    </LicaPage>
  );
}

export default ProductVideo;

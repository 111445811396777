import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Video from './video';
import { NIUM_NEW_SCENE, NIUM_NEW_SCENE_LISTICLE } from './video-data';

interface Message {
  text: string;
  sender: 'user' | 'bot';
}

export default function Chat({ videoData }: any) {
  const [messages, setMessages] = useState<Message[]>([]);
  const [storyInput, setStoryInput] = useState<string>('');
  const [toneInput, setToneInput] = useState<string>('');
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const navigate = useNavigate();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const [internalVideoData, setInternalVideoData] = useState<any>(videoData);
  const [dimension, setDimension] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Send two separate automated messages after first render
    const automatedMessages = [
      'Hi Purvanshi, what can I turn into a video today?',
      'Drop the text or link to the content here.',
    ];

    automatedMessages.forEach((msg, index) => {
      setTimeout(() => {
        setMessages((prevMessages) => [
          ...prevMessages,
          { text: msg, sender: 'bot' },
        ]);
      }, index * 1100);
    });
  }, []);

  useEffect(() => {
    if (isGenerating) {
      const currentStoryInput = storyInput;
      setStoryInput('');
      setToneInput('');
      setTimeout(() => {
        if (currentStoryInput.includes('deepscribe-deepens-integration-with-epic-laying')) {
          navigate('/v2/pages/dfsdfsddscHa4BXB2vHX');
          setIsGenerating(false);
        } else {
          setStoryInput('');
          setShowVideo(true);
          setIsGenerating(false);
        }
      }, 10000);
    }
  }, [isGenerating, navigate]);

  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages, isGenerating, showVideo]);

  const groupMessages = (messages: Message[]) => {
    const groupedMessages: { sender: string; texts: string[] }[] = [];
    let currentSender = '';
    let currentTexts: string[] = [];

    messages.forEach((message) => {
      if (message.sender === currentSender) {
        currentTexts.push(message.text);
      } else {
        if (currentSender) {
          groupedMessages.push({ sender: currentSender, texts: currentTexts });
        }
        currentSender = message.sender;
        currentTexts = [message.text];
      }
    });

    if (currentSender) {
      groupedMessages.push({ sender: currentSender, texts: currentTexts });
    }

    return groupedMessages;
  };

  const getMessageStyle = (text: string, sender: string, textIndex: number) => {
    const isLongMessage = text.length > 100;
    if (sender === 'user') {
      return isLongMessage
        ? 'self-end !bg-chat-background px-4 py-2 rounded-l-lg rounded-t-lg rounded-br-md'
        : textIndex === 0
          ? 'self-end !bg-chat-background px-4 py-2 rounded-l-3xl rounded-t-3xl rounded-br-md'
          : 'self-end !bg-chat-background px-4 py-2 rounded-l-3xl rounded-t-3xl rounded-br-3xl rounded-tr-md';
    }
    return isLongMessage
      ? 'self-start !bg-chat-background px-4 py-2 rounded-r-lg rounded-t-lg rounded-bl-2xl'
      : textIndex === 0
        ? 'self-start !bg-chat-background px-4 py-2 rounded-r-2xl rounded-t-2xl rounded-bl-2xl rounded-bl-none'
        : 'self-start !bg-chat-background px-4 py-2 rounded-r-2xl rounded-t-2xl rounded-tl-none rounded-bl-2xl';
  };

  const groupedMessages = groupMessages(messages);

  const updateComponentStyles = (components: any[], colors: Record<string, string>) => {
    const updatedComponents = [];
    for (const component of components) {
      const updatedComponent = {
        ...component,
      };
      updatedComponent.style = {
        ...component.style,
      };
      if ('color' in updatedComponent.style && updatedComponent.style.color in colors) {
        updatedComponent.style.color = colors[updatedComponent.style.color];
      }
      if ('backgroundColor' in updatedComponent.style && updatedComponent.style.backgroundColor in colors) {
        updatedComponent.style.backgroundColor = colors[updatedComponent.style.backgroundColor];
      }
      if (updatedComponent.type === 'GROUP') {
        updatedComponent.components = updateComponentStyles(component.components, colors);
      }
      updatedComponents.push(updatedComponent);
    }

    return updatedComponents;
  };

  const handleSend = () => {
    if (storyInput.trim() || toneInput.trim()) {
      setIsGenerating(true);
      const userMessage = `${storyInput}\n${toneInput}`;
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: userMessage, sender: 'user' },
      ]);
      // setStoryInput('');
      // setToneInput('');

      // Simulating a response from ChatGPT
      if (!showVideo) {
        setTimeout(() => {
          let defaultMessage = 'Got it! I’ll turn this article into a teaser for youtube with a CTA in under 15 seconds.';

          if (storyInput.includes('deepscribe-deepens-integration-with-epic-laying')) {
            defaultMessage = 'Got it, will turn three articles into a video. Retrieving brand guidelines for Deepscribe.';
          }

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: defaultMessage,
              sender: 'bot',
            },
          ]);
        }, 1000);
      }
      const lowerCaseMessage = userMessage.toLowerCase();

      // Static check for specific text format
      if (lowerCaseMessage.includes('replace') && lowerCaseMessage.includes('unveils') && lowerCaseMessage.includes('reveals')) {
        setIsSaving(true);

        setTimeout(() => {
          const oldText = 'Unveils Smart Glasses';
          const newText = 'Reveals Latest Smart Glasses';
          replaceSlideText(oldText, newText);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Updated! The title now says “Reveals Latest Smart Glasses.”', sender: 'bot' },
          ]);
        }, 3000);
      }

      if (lowerCaseMessage.includes('replace') && lowerCaseMessage.includes('video')) {
        setIsSaving(true);

        setTimeout(() => {
          const oldVideo = 'https://storage.googleapis.com/lica-assets/nium-1.mp4';
          const newVideo = 'https://storage.googleapis.com/lica-assets/nium-2.mp4';
          replaceVideo(oldVideo, newVideo);
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Visual replaced!', sender: 'bot' },
          ]);
        }, 3000);
      }

      if (lowerCaseMessage.includes('add') && lowerCaseMessage.includes('scene')) {
        setIsSaving(true);

        setTimeout(() => {
          const updatedSlides = [
            ...internalVideoData.slides.slice(0, internalVideoData.slides.length - 1),
            NIUM_NEW_SCENE,
            ...internalVideoData.slides.slice(internalVideoData.slides.length - 1, internalVideoData.slides.length),
          ];
          updatedSlides[1].duration = 5;
          updatedSlides[3].duration = 3;
          setInternalVideoData({
            ...internalVideoData,
            slides: updatedSlides,
          });

          setMessages((prevMessages) => [
            ...prevMessages,
            {
              text: 'Completed. The new scene reads “Great for capturing everyday moments hands-free. Staying connected has never been easier!',
              sender: 'bot',
            },
          ]);
        }, 3000);
      }

      if (lowerCaseMessage.includes('change') && lowerCaseMessage.includes('layout')) {
        setIsSaving(true);

        setTimeout(() => {
          const updatedSlides = [
            ...internalVideoData.slides.slice(0, -2),
            NIUM_NEW_SCENE_LISTICLE,
            ...internalVideoData.slides.slice(-1),
          ];
          setInternalVideoData({
            ...internalVideoData,
            slides: updatedSlides,
          });
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Done! The layout is now a listicle.', sender: 'bot' },
          ]);
        }, 3000);
      }

      // if (lowerCaseMessage.includes('dimensions')) {
      //   setIsSaving(true);
      //   setDimension(true);
      //   setInternalVideoData({
      //     ...internalVideoData,
      //     height: 1080,
      //     width: 1920,
      //   });
      // }

      if (lowerCaseMessage.includes('update') && lowerCaseMessage.includes('colors')) {
        setIsSaving(true);

        setTimeout(() => {
          updateLastSlideBackground();
          setMessages((prevMessages) => [
            ...prevMessages,
            { text: 'Done!', sender: 'bot' },
          ]);
        }, 3000);
      }
    }
  };

  useEffect(() => {
    if (isSaving) {
      setTimeout(() => {
        setIsSaving(false);
      }, 3000);
    }
  }, [isSaving]);

  const replaceSlideText = (oldText: string, newText: string) => {
    const updateComponents = (components: any[]): any[] => components.map((component: any) => {
      if (component.type === 'TEXT' && component.text.includes(oldText)) {
        const updatedText = component.text.replace(new RegExp(oldText, 'gi'), newText);
        return { ...component, text: updatedText };
      }
      if (component.type === 'GROUP' && component.components) {
        return { ...component, components: updateComponents(component.components) };
      }
      return component;
    });

    const updatedSlides = internalVideoData.slides.map((slide: any) => ({
      ...slide,
      components: updateComponents(slide.components),
    }));
    setInternalVideoData({
      ...internalVideoData,
      slides: updatedSlides,
    });
  };

  const replaceVideo = (oldVideo: string, newVideo: string) => {
    const updateComponents = (components: any[]): any[] => components.map((component: any) => {
      if (component.type === 'VIDEO' && component.src === oldVideo) {
        return { ...component, src: newVideo };
      }
      if (component.type === 'GROUP' && component.components) {
        return { ...component, components: updateComponents(component.components) };
      }
      return component;
    });

    const updatedSlides = internalVideoData.slides.map(
      (slide: any) => ({ ...slide, components: updateComponents(slide.components) }),
    );
    // console.log(updatedSlides);
    setInternalVideoData({
      ...internalVideoData,
      slides: updatedSlides,
    });
  };

  const updateLastSlideBackground = () => {
    const updatedSlides = internalVideoData.slides.map((slide: any, index: number) => {
      if (index === internalVideoData.slides.length - 2) {
        return {
          ...slide,
          style: {
            ...slide.style,
            background: 'linear-gradient(to right, #833ab4, #fd1d1d, #fcb045)',
          },
        };
      }
      return slide;
    });

    setInternalVideoData({
      ...internalVideoData,
      slides: updatedSlides,
    });
  };

  return (
    <div className="flex gap-5 w-full">
      {showVideo && (
        <Video isSaving={isSaving} dimension={dimension} videoData={internalVideoData} />
      )}
      <div
        className={`flex flex-col h-[70vh] ${showVideo ? 'w-4/12 min-w-96' : 'w-full'} max-w-2xl mx-auto border border-secondary border-opacity-20 rounded-[2rem]`}
      >
        <div className="flex-1 p-4 overflow-y-auto flex flex-col custom-scrollbar">
          {groupedMessages.map((group, index) => (
            <div
              key={index}
              className={`flex items-start ${group.sender === 'user' ? 'justify-end' : 'justify-start'} my-2`}
            >
              <div className="flex items-center">
                {group.sender === 'bot' && (
                  <img
                    src="/interactive-demo/video-agent.svg"
                    alt="bot"
                    className="w-8 h-8 rounded-full mr-2"
                  />
                )}
                <div className="flex max-w-lg flex-col gap-1">
                  {group.texts.map((text, textIndex) => (
                    <div
                      key={textIndex}
                      className={`${getMessageStyle(text, group.sender, textIndex)} p-2 break-words`}
                    >
                      {text}
                    </div>
                  ))}
                  {group.sender === 'bot' && index === groupedMessages.length - 1 && (
                    <div className="text-left tracking-wide text-tertiary text-xs text-gray-500">
                      Lica's Video Agent
                    </div>
                  )}
                </div>
                {group.sender === 'user' && (
                  <img
                    src="/interactive-demo/priyaa.png"
                    alt="user"
                    className="w-8 h-8 rounded-full ml-2"
                  />
                )}
              </div>
            </div>
          ))}
          <div ref={messagesEndRef} />
        </div>
        <div className="flex flex-col h-fit gap-2 p-3">
          {(!isGenerating && !showVideo) ? (
            <textarea
              className={`flex-1 outline-none dark:text-secondary-dark text-secondary-light !bg-chat-background resize-none w-full px-4 py-3 ${showVideo ? 'rounded-full' : 'min-h-28 rounded-2xl'} `}
              value={storyInput}
              onChange={(e) => setStoryInput(e.target.value)}
              placeholder="Enter your story here or paste URL link..."
            />
          ) : (
            <div className="relative">
              <input
                className="flex-1 outline-none dark:text-secondary-dark text-secondary-light !bg-chat-background px-4 py-4 pr-20 resize-none w-full rounded-full "
                value={storyInput}
                onChange={(e) => setStoryInput(e.target.value)}
                placeholder="Enter your prompt here..."
              />
              <div
                onClick={handleSend}
                className="bg-tertiary rounded-full cursor-pointer flex items-center justify-center h-10 w-12 text-white absolute right-3 top-2"
              >
                <img
                  src="/interactive-demo/send.svg"
                  alt="bot"
                  className="w-6"
                />
              </div>
            </div>
          )}
          {!isGenerating && !showVideo
            && (
              <input
                className="outline-none dark:text-secondary-dark text-secondary-light !bg-chat-background resize-none w-full px-4 py-3 rounded-full"
                value={toneInput}
                onChange={(e) => setToneInput(e.target.value)}
                placeholder="What's the tone, style, and cover design for your video?"
              />
            )}
          {!showVideo && (
            <button
              className={`${isGenerating ? 'dark:text-secondary-dark text-secondary-light gap-2 mb-10' : 'bg-tertiary'} dark:text-primary-dark text-primary-light rounded-full w-full p-2 bg-blue-500 text-white flex items-center justify-center`}
              onClick={handleSend}
              disabled={isGenerating}
            >
              {isGenerating ? (
                <>
                  <svg
                    className="animate-spin ml-2 h-5 w-5 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path
                      className="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    />
                  </svg>
                  Generating...
                </>
              ) : (
                'Generate'
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );
}

import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import { Button, Input, Spacer } from '@nextui-org/react';
import { Slide } from '@mui/material';
import { CallbackListener, PlayerRef } from '@remotion/player';
import { Icon } from '@iconify/react';
import dayjs from 'dayjs';

import { isHyperlink } from '@/utils/common';
import AuthContext from '@/auth/context';
import RemotionPlayer from '@/remotion/RemotionPlayer';
import useFetch from '@/hooks/useFetch';

import ChatBox from '@/pages/v3/edit-content/components/chatbox';
import ControlBar from './control-bar';

export function EditPodcastChatNew({ data, podcastId, responseData }: any) {
  const authContext = useContext(AuthContext);
  const playerRef = useRef<PlayerRef>(null);

  const [contentData, setContentData] = useState<any>(data);
  const [openEditImageModal, setOpenEditImageModal] = useState(false);
  const [editImageItem, setEditImageItem] = useState<any>();
  const [titleComponent, setTitleComponent] = useState<any>(contentData.title);
  const [imageComponent, setImageComponent] = useState<any>(contentData.image);
  const [transcriptComponent, setTranscriptComponent] = useState<any>(contentData.transcript);
  const [textHeights, setTextHeights] = useState<number[]>([]);
  const [generatedTranscript, setGeneratedTranscript] = useState<any>();

  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);

  const [messages, setMessages] = useState<any>([
    {
      id: 1,
      image: '',
      receiver: 'Lica',
      text: `Hello, ${responseData.user_info?.name}. Let me know how you want the podcast to be edited, and I will make those edits for you. Eg) Add a new call to action to subscribe to the podcast in the end.`,
    },
  ]);

  const { fetchData: getContent } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${podcastId}/`,
    { method: 'GET' },
  );
  const { fetchData: saveData } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${podcastId}/update`,
    { method: 'POST' },
  );
  const { fetchData: downloadImage } = useFetch<any>(
    `${import.meta.env.VITE_BACKEND_URL}/api/v1/images/`,
    { method: 'POST' },
  );

  const onSetTitleChange = ((event: any) => {
    setTitleComponent(event.target.value);
  });

  const toggleModal = () => setOpenEditImageModal(!openEditImageModal);

  const onOpenEditImage = (src: string | undefined): void => {
    setOpenEditImageModal(true);
    setEditImageItem({ src });
  };

  const onSaveEditImage = async () => {
    setOpenEditImageModal(false);
    try {
      const downloadResponse = await downloadImage({ body: JSON.stringify(editImageItem.src) });
      setImageComponent(downloadResponse.data.url);
    } catch (err) {
      console.log(err);
      setImageComponent(editImageItem.src);
    }
    setEditImageItem({});
  };

  const handleTextChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number | null = null,
    key: string | null = null,
  ) => {
    if (index !== null && key !== null) {
      const newTranscript = [...contentData.transcript];
      newTranscript[index][key] = e.target.value;
      setTranscriptComponent(newTranscript);
    } else {
      setTranscriptComponent(e.target.value);
    }

    // Calculate the new height
    const newHeight = e.target.scrollHeight;
    setTextHeights((prevHeights) => {
      const updatedHeights = [...prevHeights];
      if (index !== null) {
        updatedHeights[index] = newHeight;
      }
      return updatedHeights;
    });
  };

  const textAreasRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  useEffect(() => {
    const newHeights = textAreasRefs.current.map(
      (textarea) => (textarea ? textarea.scrollHeight : 0),
    );
    setTextHeights(newHeights);
  }, [contentData.transcript]);

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setContentData(contentResponseData.data.output);
        setIsProcessing(false);

        setMessages((currentMessages: any) => ([
          ...currentMessages,
          {
            id: currentMessages.length + 1,
            image: '',
            receiver: 'Lica',
            text: 'Updated. Is there anything else you would like to change?',
          },
        ]));
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);
      } else {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);
      setTimeout(() => {
        fetchContent();
      }, 5000);
    }

    if (!playerRef.current) return;

    const onPlay: CallbackListener<'play'> = () => {
      setIsPlaying(true);
    };
    const onPause: CallbackListener<'pause'> = () => {
      setIsPlaying(false);
    };

    playerRef.current.addEventListener('play', onPlay);
    playerRef.current.addEventListener('pause', onPause);

    return () => {
      if (playerRef.current) {
        playerRef.current.removeEventListener('play', onPlay);
        playerRef.current.removeEventListener('pause', onPause);
      }
    };
  }, []);

  useEffect(() => {
    setTitleComponent(contentData.title);
    setImageComponent(contentData.image);
    setTranscriptComponent(contentData.transcript);
  }, [contentData]);

  const isSaveButtonEnabled = () => {
    if (imageComponent !== contentData.image) {
      return true;
    }

    if (titleComponent !== contentData.title) {
      return true;
    }

    return transcriptComponent !== contentData.transcript;
  };

  const handleSaveClick = async () => {
    if (isProcessing) return;

    setIsProcessing(true);

    const actions = [];

    const actionPayload = {
      id: 'update_content',
      attributes: {},
    };

    if (imageComponent !== contentData.image) {
      Object.assign(actionPayload.attributes, { image: imageComponent });
    }

    if (titleComponent !== contentData.title) {
      Object.assign(actionPayload.attributes, { title: titleComponent });
    }

    if (transcriptComponent !== contentData.transcript) {
      Object.assign(actionPayload.attributes, { transcript: transcriptComponent });
    }

    actions.push(actionPayload);

    try {
      const saveResponse = await saveData({
        body: JSON.stringify({ actions }),
      });

      if (['initiated', 'processing'].includes(saveResponse.data.update_status)) {
        await fetchContent();
      } else {
        setIsProcessing(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadClick = async () => {
    if (isDownloading) return;

    setIsDownloading(true);

    const payload = {
      composition: 'Template',
      inputProps: contentData.audio_data,
    };
    try {
      const response = await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/download/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      const result = await response.json();

      const downloadLink = document.createElement('a');
      downloadLink.href = result.outputFile;
      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      setIsDownloading(false);
    } catch (err) { /* empty */ }
  };

  const updatePodcast = async (prompt: any) => {
    if (isProcessing) return;

    const token = await authContext.currentUser?.getIdToken();
    if (!token) return;

    setIsProcessing(true);

    const payload = {
      prompt,
    };

    await fetch(`${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${podcastId}/update`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(payload),
    });

    await fetchContent();
  };

  const handleSend = async (inputValue: string) => {
    if (inputValue.trim() !== '') {
      setMessages((currentMessages: any) => ([
        ...currentMessages,
        {
          id: currentMessages.length + 1,
          image: '',
          sender: responseData.user_info?.name || 'User',
          text: inputValue,
        },
      ]));
      await updatePodcast(prompt);
    }
  };

  const updateDialogueTranscript = (transcript: any) => {
    let i = -1;
    let newTranscript: any[] = [];
    let currentDialogue: any = null;
    const interval = setInterval(() => {
      if (i === -1) {
        setTranscriptComponent(newTranscript);
        i += 1;
      } else if (i < transcript.length) {
        if (!currentDialogue) {
          currentDialogue = { ...transcript[i] };
          if ('speaker_1' in currentDialogue) {
            currentDialogue.speaker_1 = '';
          } else {
            currentDialogue.speaker_2 = '';
          }

          newTranscript = [...newTranscript, currentDialogue];
        } else if ('speaker_1' in currentDialogue) {
          const currentDialogueWords = currentDialogue.speaker_1.split(' ');
          const allWords = transcript[i].speaker_1.split(' ');

          if (!currentDialogue.speaker_1) {
            currentDialogueWords.push(allWords[0]);
            currentDialogue.speaker_1 = currentDialogueWords.join(' ');
            newTranscript = [...newTranscript.slice(0, newTranscript.length - 1), currentDialogue];
          } else if (allWords.length > currentDialogueWords.length) {
            currentDialogueWords.push(allWords[currentDialogueWords.length]);
            currentDialogue.speaker_1 = currentDialogueWords.join(' ');
            newTranscript = [...newTranscript.slice(0, newTranscript.length - 1), currentDialogue];
          } else {
            currentDialogue = null;
            i += 1;
          }
        } else {
          const currentDialogueWords = currentDialogue.speaker_2.split(' ');
          const allWords = transcript[i].speaker_2.split(' ');

          if (!currentDialogue.speaker_2) {
            currentDialogueWords.push(allWords[0]);
            currentDialogue.speaker_2 = currentDialogueWords.join(' ');
            newTranscript = [...newTranscript.slice(0, newTranscript.length - 1), currentDialogue];
          } else if (allWords.length > currentDialogueWords.length) {
            currentDialogueWords.push(allWords[currentDialogueWords.length]);
            currentDialogue.speaker_2 = currentDialogueWords.join(' ');
            newTranscript = [...newTranscript.slice(0, newTranscript.length - 1), currentDialogue];
          } else {
            currentDialogue = null;
            i += 1;
          }
        }

        setTranscriptComponent(newTranscript);
      } else if (i >= transcript.length) {
        clearInterval(interval);

        const newMessages = [
          {
            id: messages.length + 2,
            image: '',
            receiver: 'Lica',
            text: 'Updated. Is there anything else you would like to change?',
          },
        ];
        setMessages([...messages, ...newMessages]);
      }
    }, 30);
  };

  useEffect(() => {
    if (!generatedTranscript) return;

    if (Array.isArray(contentData.transcript)) {
      updateDialogueTranscript(generatedTranscript);
    }
  }, [generatedTranscript]);

  return (
    <div className="p-8 sm:p-4">
      <div className="bg-white rounded-2xl p-8 sm:p-4  flex flex-col items-center justify-center">
        <div className="flex justify-center gap-10 items-center h-full">
          <div
            className=" max-w-[50rem] rounded-lg h-full flex flex-col-reverse w-fit gap-3 justify-center items-center"
          >
            <Slide direction="right" in mountOnEnter>
              <div className="rounded-lg flex justify-center items-center flex-col">
                <div
                  className="w-full br-5 rounded-md aspect-square"
                >
                  <div className="min-w-[300px] aspect-square">
                    <RemotionPlayer
                      ref={playerRef}
                      data={contentData.orientations.square}
                    />
                  </div>
                </div>
              </div>
            </Slide>
            <div className="rounded-lg p-4 sm:p-0 flex justify-center items-center flex-col">
              <div className="flex gap-5 p-0 mb-5 w-full">
                {openEditImageModal && (
                  <div
                    className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center animate-fadeIn z-20 rounded-xl"
                    onClick={toggleModal}
                  >
                    <div
                      className="dark:bg-secondary-dark bg-secondary-light p-5 rounded-lg animate-slideIn w-4/5 max-w-xs"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Input
                        type="text"
                        radius="sm"
                        label="Media URL"
                        placeholder="https://media.com/image"
                        labelPlacement="outside"
                        startContent={<Icon icon="ph:link-bold" />}
                        value={editImageItem.src}
                        isInvalid={!isHyperlink(editImageItem.src)}
                        onValueChange={
                          (value) => setEditImageItem({ ...editImageItem, src: value })
                        }
                      />
                      <div className="w-full mt-1 flex justify-end items-center gap-1">
                        <Button
                          radius="sm"
                          size="md"
                          color="primary"
                          className="dark:text-primary-dark text-primary-light-color"
                          variant="light"
                          onClick={toggleModal}
                        >
                          Cancel
                        </Button>
                        <Button
                          radius="full"
                          size="md"
                          color="primary"
                          className="text-white"
                          onClick={onSaveEditImage}
                        >
                          Update
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
                <div className="relative flex gap-2 overflow-hidden">
                  <div className="h-32 w-32 relative">
                    <img className="h-32 w-32 rounded-lg object-cover" src={imageComponent} />
                    <div
                      onClick={() => onOpenEditImage(imageComponent)}
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-black bg-opacity-50 text-white p-2 md:p-4 rounded cursor-pointer hover:opacity-100 opacity-0 transition-opacity duration-300 w-full h-full flex justify-center items-center"
                    >
                      <Button
                        onClick={() => onOpenEditImage(imageComponent)}
                        size="sm"
                        color="primary"
                        className="dark:text-primary-dark text-primary-light"
                        isIconOnly
                        style={{
                          borderRadius: '50%',
                          padding: '0',
                          background: 'white',
                        }}
                      >
                        <Icon icon="iconamoon:edit" className="dark:text-primary-dark text-primary-light" style={{ fontSize: '1rem' }} />
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="flex-col">
                  <div className="text-2xl sm:text-lg font-bold">
                    <textarea
                      value={titleComponent}
                      className="resize-none outline-none focus:outline-none"
                      style={{ background: 'transparent' }}
                      onChange={onSetTitleChange}
                    />
                  </div>
                  <Spacer y={3} />
                  <div className="flex gap-5 items-center">
                    <div className="flex items-start justify-left">
                      <h4 className="text-small font-semibold leading-none dark:text-secondary-dark text-secondary-light opacity-70">
                        {responseData.user_info?.name}
                      </h4>
                      <div className="mt-1 mx-3 w-2 h-2 rounded-full bg-[#c0c0c0]" />
                      <h4 className="text-small font-semibold leading-none dark:text-secondary-dark text-secondary-light opacity-70">
                        {dayjs(responseData.created_at).format('DD MMM YYYY')}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <ControlBar
                data={data}
                isPlaying={isPlaying}
                isDownloading={isDownloading}
                isProcessing={isProcessing}
                playerRef={playerRef}
                handleDownloadClick={handleDownloadClick}
                handleSaveClick={handleSaveClick}
                isSaveButtonEnabled={isSaveButtonEnabled}
              />
            </div>
          </div>
          <div
            className="bg-white w-5/12 sm:hidden md:hidden rounded-lg h-full p-0 flex flex-col items-center justify-center"
          >
            <div className="w-full mb-5 flex flex-col gap-1 p-6 border border-[#EAECF0] rounded-lg">
              <h1 className="text-xl font-semibold mb-1">Transcript</h1>
              {Array.isArray(contentData.transcript) && Array.isArray(transcriptComponent)
                ? (
                  <div className="flex flex-col h-60 overflow-y-auto">
                    {transcriptComponent?.map((text: any, index: any) => (
                      Object.entries(text).map(([key, value]) => {
                        let speaker = '';
                        const speakerName = key.split('_')
                          .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
                          .join(' ');
                        speaker += `${speakerName}`;

                        return (
                          <div key={`${index}-${key}`}>
                            <p className="text-small font-semibold mb-1">{speaker}</p>
                            <textarea
                              value={`${value as string}`}
                              className="w-full resize-none outline-none focus:outline-none overflow-hidden text-white"
                              onChange={(e) => handleTextChange(e, index, key)}
                              ref={(el) => {
                                textAreasRefs.current[index] = el;
                              }}
                              style={{ minHeight: `${textHeights[index]}px`, background: '#0A0A0A' }}
                            />
                          </div>
                        );
                      })
                    ))}
                  </div>
                ) : (
                  <textarea
                    value={transcriptComponent}
                    className="w-full py-4 h-auto min-h-52 resize-none outline-none focus:outline-none bg-[#000000] text-white"
                    onChange={(e) => handleTextChange(e)}
                  />
                )}
            </div>
            <ChatBox
              messages={messages}
              handleSend={handleSend}
              isProcessing={isProcessing}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

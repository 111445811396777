import { ForwardedRef, forwardRef, useEffect } from 'react';
import { Player, RenderPoster } from '@remotion/player';

import Template from './template';
import { loadFonts } from './fonts';

const RemotionPlayer = forwardRef(
  (
    {
      data,
      template,
      clickToPlay = true,
      customNumber = undefined,
      onComponentChange,
    }: {
      data: any;
      template?: any;
      clickToPlay?: boolean;
      customNumber?: number;
      onComponentChange?: (componentID: any, oldValue: any, newValue: any) => void;
    },
    ref: ForwardedRef<any>,
  ) => {
    let duration = 0;

    if (template) {
      duration = data.duration * data.fps;
    } else {
      data.slides?.forEach((slide: any) => {
        duration += Math.ceil(slide.duration * data.fps);
      });
    }

    function findFontFamilies(obj: any): string[] {
      const fontFamilies = new Set<string>();

      function traverse(item: any) {
        if (typeof item !== 'object' || item === null) {
          return;
        }

        if (Object.prototype.hasOwnProperty.call(item, 'fontFamily')) {
          fontFamilies.add(item.fontFamily);
        }

        Object.keys(item).forEach((key) => {
          traverse(item[key]);
        });
      }

      traverse(obj);
      return Array.from(fontFamilies);
    }

    useEffect(() => {
      const fontFamilies = findFontFamilies(data);
      loadFonts(fontFamilies);
    }, [data]);

    const renderPoster: RenderPoster = () => (
      customNumber === 1 ? (
        <img src="/custom-1.jpeg" />
      ) : customNumber === 2 ? (
        <img src="/custom-2.jpeg" />
      ) : customNumber === 3 ? (
        <img src="/custom-3.jpeg" />
      ) : null
    );

    const handleChange = (componentID: any, oldValue: any, newValue: any) => {
      if (onComponentChange) {
        onComponentChange(componentID, oldValue, newValue);
      }
    };

    return (
      <Player
        ref={ref}
        style={{ width: '100%', borderRadius: '8px' }}
        component={template || Template}
        inputProps={{
          player: ref,
          onChange: handleChange,
          ...data,
        }}
        durationInFrames={duration || 1}
        fps={data.fps}
        compositionWidth={data.width}
        compositionHeight={data.height}
        autoPlay={false}
        renderPoster={customNumber ? renderPoster : undefined}
        showPosterWhenUnplayed
        posterFillMode="player-size"
        clickToPlay={clickToPlay}
        controls
      />
    );
  },
);

RemotionPlayer.displayName = 'RemotionPlayer';

export default RemotionPlayer;

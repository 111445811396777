import { ReactNode, FC } from 'react';

const Button: FC<{ onClick?: () => void; children: ReactNode }> = ({
  onClick,
  children,
}) => (
  <div
    role="button"
    className="px-7 py-3 rounded-full bg-tertiary dark:text-primary-dark text-primary-light hover:dark:bg-secondary-dark bg-secondary-light duration-500 text-xl text-center"
    onClick={onClick}
  >
    {children}
  </div>
);

export default Button;

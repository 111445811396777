import { useContext, useEffect, useRef, useState } from 'react';
import { Icon } from '@iconify/react';
import { Button } from '@nextui-org/react';
import AuthContext from '@/auth/context';

export default function ChatBox(
  {
    messages, handleSend, isProcessing,
  }: any,
) {
  const [
    localChatInputValue,
    setLocalChatInputValue,
  ] = useState<string>('');
  const chatContainer = useRef<HTMLDivElement>(null);
  const chat = useRef<HTMLDivElement>(null);

  const authContext = useContext(AuthContext);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const handleInputChange = (e: any) => {
    const { value } = e.target;
    setLocalChatInputValue(value);
  };

  const handleKeyPress = (e: any) => {
    if (e.code === 'Enter') {
      handleSendClick();
    }
  };

  const handleSendClick = () => {
    handleSend(localChatInputValue);
    setLocalChatInputValue('');
  };

  const updateContainerHeight = () => {
    if (chatContainer.current) {
      const inputHeight = 100;
      setContainerHeight(window.innerWidth > 1024 ? chatContainer.current.clientHeight - inputHeight : 463);
    }
  };

  const scrollToBottom = () => {
    if (chat.current) {
      chat.current.scrollTop = chat.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    updateContainerHeight();
    scrollToBottom()

    const handleResize = () => {
      updateContainerHeight();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <div ref={chatContainer} className='w-full h-full flex flex-col border rounded-lx p-4 dark:border-transparent bg-[#f0f0f0] border-[#d4d4d4] dark:bg-transparent'>
      <div style={{
        maxHeight: containerHeight,
      }} className="flex flex-col justify-end rounded-t-lg flex-grow w-full">
        <div ref={chat} className='overflow-auto minimal-scrollbar rounded-t-lg pr-1 flex flex-col gap-3'>
          {messages.map((message: any) => (
            <div
              key={message.id}
              className={`${message.receiver ? 'flex gap-1 items-center' : "flex flex-row-reverse gap-1 items-center justify-start"}`}
            >
              {!message.receiver ? authContext.currentUser && (
                <img
                  className="w-8 rounded-full"
                  src={authContext.currentUser.photoURL || "/icons/default-user-icon.svg"}
                  alt="user avatar"
                  onError={(e: any) => {
                    e.target.src = "/icons/default-user-icon.svg";
                  }}
                />
              )
                :
                <img
                  className="w-8 rounded-full"
                  src="https://media.licdn.com/dms/image/v2/D560BAQG26MZwts6YFQ/company-logo_200_200/company-logo_200_200/0/1714767897109?e=1735171200&v=beta&t=flqj23RluITe611opEi1-nZW6H6mGg2PAFEkzMKLMqE"
                  alt="user avatar"
                  onError={(e: any) => {
                    e.target.src = "/icons/default-user-icon.svg";
                  }}
                />
              }
              <span className="max-w-full overflow-auto h-fit rounded-lx p-3 bg-gray-300 dark:bg-sectionBackground-dark break-words">
                {message.text}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div className="flex w-full mt-3 min-h-14 relative">
        <input
          className="w-full p-4 pr-14 h-full outline-none rounded-lx bg-primary-light border border-borderColor-light dark:bg-sectionBackground-dark dark:border-borderColor-dark"
          value={localChatInputValue}
          onChange={handleInputChange}
          placeholder='Enter your message here'
          onKeyDown={handleKeyPress}
          disabled={isProcessing}
        />
        <Button
          className="absolute right-2 top-2 bg-tertiary-dark text-black"
          onClick={handleSendClick}
          isLoading={isProcessing}
          isDisabled={isProcessing || !localChatInputValue}
          isIconOnly
        >
          {isProcessing ? null : <Icon icon="fluent:send-20-filled" style={{ fontSize: '1.5rem' }} />}
        </Button>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import { prefetch } from 'remotion';
import { Spinner } from '@nextui-org/react';

import RemotionPlayer from '../../../remotion/RemotionPlayer';
import { getMediaLinks } from '../../../utils/common';
import { NIUM_DEMO, NIUM_NEW_SCENE } from './video-data';

export default function Video({ videoData, isSaving, dimension = true }: any) {
  const [isMediaCached, setIsMediaCached] = useState(false);

  useEffect(() => {
    const promises: any[] = [];
    const mediaLinks = getMediaLinks({ a: videoData, b: NIUM_NEW_SCENE, c: NIUM_DEMO });
    mediaLinks.forEach((link) => {
      promises.push(prefetch(link).waitUntilDone());
    });
    Promise.allSettled(promises).then(() => {
      setIsMediaCached(true);
    });
  }, []);

  return (
    <div className={`w-full ${dimension ? 'flex-col' : 'px-7'} overflow-hidden gap-5 flex rounded-3xl items-start justify-between h-[70vh] bg-chat-background z-20`}>
      <div className={`${dimension ? 'w-full px-5' : 'w-4/12'} py-5 flex justify-between `}>
        <div className="w-full text-xl">Meta Unveils Smart Glasses And AI Updates</div>
      </div>
      <div className={`${!dimension ? 'min-w-[18.5rem] h-full' : 'w-full h-full'} relative `}>
        {isMediaCached ? (
          <RemotionPlayer data={videoData} clickToPlay={false} />
        ) : (
          <div className="flex items-center justify-center h-full">
            <Spinner color="current" />
          </div>
        )}
        {isSaving && (
          <div className="absolute dark:bg-primary-dark bg-primary-light z-50 flex items-center justify-center top-0 bg-opacity-60 w-full h-full">
            <div className="dark:bg-primary-dark bg-primary-light dark:text-secondary-dark text-secondary-light rounded-xl px-5 py-3 absolute">Generating...</div>
          </div>
        )}
      </div>
      {!dimension && (
        <div className="w-3/12 flex justify-end">
          {/* <div className="mt-5 w-fit py-1 px-3 dark:bg-primary-dark bg-primary-light rounded-lg">Scene 1</div> */}
        </div>
      )}
    </div>
  );
}

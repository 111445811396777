import { useEffect, useRef } from 'react';

import useFetch from '@/hooks/useFetch';
import RemotionPlayer from '@/remotion/RemotionPlayer';

function RemotionPlayerContainer({
  videoId,
  internalVideoData,
  addNewSlideIndex,
  setAddNewSlideIndex,
  setInternalVideoData,
  setIsProcessing,
  regenerate,
  responseData,
  editSlideIndex,
  setEditSlideIndex,
  setChatMessage,
  seekValue,
  onComponentChange,
}: {
  videoId: string;
  internalVideoData: any;
  responseData: any;
  setInternalVideoData: (data: any) => void;
  setIsProcessing: (val: boolean) => void;
  regenerate: boolean;
  addNewSlideIndex: number | null;
  setAddNewSlideIndex: (index: number | null) => void;
  editSlideIndex: number | null;
  setEditSlideIndex: (index: number | null) => void;
  setChatMessage: (message: string) => void;
  seekValue: number | null;
  onComponentChange: (componentID: string, oldValue: string, newValue: string) => void;
}) {
  const playerRef = useRef<any>(null);
  const { fetchData: getContent } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/contents/${videoId}/`, {
    method: 'GET',
  });

  const fetchContent = async () => {
    try {
      const contentResponseData = await getContent();

      if (contentResponseData?.data?.update_status === 'completed') {
        setInternalVideoData(contentResponseData.data.output);
        setIsProcessing(false);
        setChatMessage('The video is updated! Is there anything else you would like to change?');

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (contentResponseData?.data?.update_status === 'failed' || !contentResponseData?.data?.update_status) {
        setIsProcessing(false);
        setChatMessage("I'm facing some issues while making the changes. Could you please try again?");

        if (addNewSlideIndex !== null) {
          setAddNewSlideIndex(null);
        }
        if (editSlideIndex !== null) {
          setEditSlideIndex(null);
        }
      } else if (['initiated', 'processing'].includes(contentResponseData?.data?.update_status)) {
        setTimeout(() => {
          fetchContent();
        }, 5000);
      }
    } catch (err) {
      /* empty */
    }
  };

  useEffect(() => {
    if (regenerate) {
      fetchContent();
    }
  }, [regenerate]);

  useEffect(() => {
    if (['initiated', 'processing'].includes(responseData?.update_status)) {
      setIsProcessing(true);

      setTimeout(() => {
        fetchContent();
      }, 5000);
    }
  }, []);

  useEffect(() => {
    if (playerRef.current && seekValue) {
      playerRef.current?.seekTo(seekValue * internalVideoData.video_data.fps);
    }
  }, [seekValue]);

  return (
    <div className="w-full min-lg:min-w-[41.666667%] min-lg:w-5/12 h-full flex flex-col justify-center gap-2">
      <div
        className="dark:bg-sectionBackground-dark bg-[#f0f0f0] border border-[#d4d4d4] dark:border-borderColor-dark  w-full h-full rounded-xl p-6 py-8 flex flex-col items-center gap-6"
      >
        <div className="w-full text-2xl font-medium flex items-center justify-center gap-4 line-clamp-1">
          {internalVideoData.title}
        </div>
        <div
          style={{
            aspectRatio: (
              internalVideoData?.video_data?.width / internalVideoData?.video_data?.height
            ) || 16 / 9,
          }}
          className="rounded-xl flex-auto max-w-full max-h-full flex items-center"
        >
          {internalVideoData?.video_data && (
            <RemotionPlayer
              ref={playerRef}
              data={internalVideoData.video_data}
              clickToPlay={false}
              onComponentChange={onComponentChange}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default RemotionPlayerContainer;

import React, {
  useEffect, useState, useRef, useContext,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Spacer, Spinner,
} from '@nextui-org/react';
import { Icon } from '@iconify/react';

import AuthContext from '@/auth/context';
import LicaPage from '@/components/LicaPage';
import useFetch from '@/hooks/useFetch';
import { getCurrentDeviceType } from '@/utils/common';

import BlogControls from './blog';
import VideoControls from './video';
import PodcastControls from './podcast';
import styles from './index.module.css';

function Generate() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [contentOptions, setContentOptions] = useState<any>({});
  const [isLoadingOptions, setIsLoadingOptions] = useState<boolean>(true);
  const [isUploading, setIsUploading] = useState(false);
  const [loadingMedia, setLoadingMedia] = useState<Set<string>>(new Set());

  const [prompt, setPrompt] = useState('');
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [mediaPreviews, setMediaPreviews] = useState<{ src: string, type: string }[]>([]);
  const [showControl, setShowControl] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const {
    data: getData,
    triggerFetch: triggerGetFetch,
  } = useFetch<any>(`${import.meta.env.VITE_BACKEND_URL}/api/v1/content-options/`, { method: 'GET' });

  const [
    blogControls, setBlogControls,
  ] = useState({ stylePrompt: '' });
  const [
    videoControls, setVideoControls,
  ] = useState({
    stylePrompt: '',
    voiceType: null,
    actorId: null,
    orientation: null,
    voiceover: true,
    music: true,
    videoType: 'voiceover',
    templateId: null,
    templateStyle: null,
    voiceId: null,
  });
  const [
    podcastControls, setPodcastControls,
  ] = useState({
    stylePrompt: '',
    podcastType: 'narrator',
    voiceType: null,
    music: true,
    textData: '',
    podcastStyle: null,
    voiceIds: null,
  });
  const [isGenerating, setIsGenerating] = useState(false);

  useEffect(() => {
    triggerGetFetch();
    if (!getData) return;
    setContentOptions(getData.data);
    setIsLoadingOptions(false);
  }, [getData]); // Empty dependency array ensures this runs only once on mount

  useEffect(() => {
    setPrompt(podcastControls.textData);
  }, [podcastControls.textData]);
  const authContext = useContext(AuthContext);

  const contentType = searchParams.get('type') || 'page';
  const contentTypes = contentType.split(',');

  const handleFileChange = (
    event: React.ChangeEvent<HTMLInputElement> | React.DragEvent<HTMLDivElement>,
  ) => {
    let files: File[] = [];

    if (event.type === 'change') {
      files = Array.from((event.target as HTMLInputElement).files || []);
    } else if (event.type === 'drop') {
      files = Array.from((event as React.DragEvent<HTMLDivElement>).dataTransfer.files || []);
    }

    setIsUploading(true);

    const validFiles = files.filter((file) => {
      if (file.size > 100 * 1024 * 1024) {
        alert(`${file.name} is too large. Maximum file size is 100MB.`);
        return false;
      }
      return file.type.startsWith('image/') || file.type.startsWith('video/');
    });

    setSelectedFiles((existingFiles) => [...existingFiles, ...validFiles]);

    const newPreviews = validFiles.map((file) => ({
      src: URL.createObjectURL(file),
      type: file.type.startsWith('image/') ? 'image' : 'video',
    }));
    setMediaPreviews((prev) => [...prev, ...newPreviews]);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }

    setIsUploading(false);
  };

  const handleRemoveMedia = (index: number) => {
    const updatedPreviews = mediaPreviews.filter((_, i) => i !== index);
    setMediaPreviews(updatedPreviews);

    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    handleFileChange(event);
  };

  const onClick = async () => {
    if (!prompt || prompt.length < 8) return;

    setIsGenerating(true);

    const data: any = {
      prompt,
      content_types: [],
    };

    if (contentOptions.PAGE.content_types.includes('VIDEO') && (contentTypes.includes('page') || contentTypes.includes('video'))) {
      data.content_types.push({
        type: 'VIDEO',
        params: {
          style_prompt: videoControls.stylePrompt,
          video_type: videoControls.videoType,
          voice_type: videoControls.voiceType,
          presenter_id: videoControls.actorId,
          music: videoControls.music,
          orientation: videoControls.orientation,
          template_style: videoControls.templateStyle,
          template_id: videoControls.templateId,
          voice_id: videoControls.voiceId,
          voiceover: videoControls.voiceover,
          device: getCurrentDeviceType(),
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('PODCAST') && (contentTypes.includes('page') || contentTypes.includes('podcast'))) {
      data.content_types.push({
        type: 'PODCAST',
        params: {
          style_prompt: podcastControls.stylePrompt,
          podcast_type: podcastControls.podcastType,
          voice_type: podcastControls.voiceType,
          music: podcastControls.music,
          podcast_style: podcastControls.podcastStyle,
          voice_ids: podcastControls.voiceIds,
        },
      });
    }
    if (contentOptions.PAGE.content_types.includes('BLOG') && (contentTypes.includes('page') || contentTypes.includes('blog'))) {
      data.content_types.push({
        type: 'BLOG',
        params: {
          style_prompt: blogControls.stylePrompt,
        },
      });
    }
    const token = await authContext.currentUser?.getIdToken();
    const updatedFormData = new FormData();

    selectedFiles.forEach((file) => {
      updatedFormData.append('files', file);
    });

    updatedFormData.append('data', JSON.stringify(data));
    try {
      const postResponse = await fetch(
        `${import.meta.env.VITE_BACKEND_URL}/api/v1/pages/`,
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: updatedFormData,
        },
      ).then((res) => res.json());

      if (postResponse) {
        const { currentUser } = authContext;
        if (currentUser?.email === 'purvanshi@lica.world' || currentUser?.email === 'mohit@lica.world') {
          if (prompt.includes('about.fb.com/news/2024/04/new-ray-ban-meta-sma')) {
            setTimeout(() => {
              navigate('/pages/GL0HakVKr7jDaL7GCynt/');
            }, 10000);
          } else if (prompt.includes('is an adorable 3 year old husky-alaskan malamute mixing living his best life in california. He is the kindest, goofiest dog in the whole world. He was abandonned in a shelter and always holds a positive atti')) {
            setTimeout(() => {
              navigate('/pages/YEn3Wbkh8Q4b62Sg2ZvZ/');
            }, 10000);
          } else if (prompt.includes('Mexico has consolidated itself as one of the most important countries in global manufacturing and')) {
            setTimeout(() => {
              navigate('/pages/nzuUGVThGbST9PRlEFCB/');
            }, 10000);
          } else if (prompt.includes('s https://www.deepscribe.ai/resources/will-traditional-medical-transcription-become-obsolete and generate a custom tem')) {
            setTimeout(() => {
              navigate('/v2/pages/yhtrfdgddscHa4BXB2vHX/');
            }, 10000);
          } else if (prompt.includes('eo in which every image is a cartoon-style character of a husky dog named Dobby. The story should be around how Dobby loves watching videos but is not that good at reading. He wants t')) {
            setTimeout(() => {
              navigate('/pages/Qtbn6vQRNrTqPlKcQjmI/');
            }, 10000);
          } else if (prompt.includes('wishlink')) {
            setTimeout(() => {
              navigate('/pages/75wuc0o4ncdIQNkhUzrW/');
            }, 10000);
          } else if (prompt.includes('instagram')) {
            setTimeout(() => {
              navigate('/pages/FLkKFYq9j70Ubutp5HF9/');
            }, 10000);
          } else {
            navigate(`/pages/${postResponse.data.page_id}/`);
          }
        } else {
          navigate(`/pages/${postResponse.data.page_id}/`);
        }
      }
    } finally {
      const { currentUser } = authContext;
      if (currentUser?.email !== 'purvanshi@lica.world' && currentUser?.email !== 'mohit@lica.world') {
        setIsGenerating(false);
      }
    }
  };

  const handleMediaLoad = (src: string) => {
    setLoadingMedia((prev) => {
      const updated = new Set(prev);
      updated.delete(src);
      return updated;
    });
  };

  const handleClickForReels = async () => {
    if (isUploading) return;

    const randomImageUrls = [
      'https://images.unsplash.com/photo-1720774531704-dff00e791a55?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1719217469234-bb53c12ed515?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
      'https://images.unsplash.com/photo-1718556256225-82afc1b30580?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    ];

    setIsUploading(true);

    for (const [index, url] of randomImageUrls.entries()) {
      if (mediaPreviews.some((preview) => preview.src === url)) {
        continue;
      }

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const file = new File([blob], `image${index}.jpg`, { type: 'image/jpeg' });

        if (file.size > 100 * 1024 * 1024) {
          alert(`${file.name} is too large. Maximum file size is 100MB.`);
          continue;
        }

        setSelectedFiles((existingFiles) => [...existingFiles, file]);

        const newPreview = {
          src: url,
          type: 'image',
        };

        setMediaPreviews((prev) => [...prev, newPreview]);
      } catch (error) {
        console.error(`Error fetching image at ${url}:`, error);
      }
    }

    setIsUploading(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <LicaPage>
      {isLoadingOptions ? (
        <div className="flex items-center justify-center h-[calc(100vh-128px)]">
          <Spinner color="current" />
        </div>
      ) : (
        <div className="flex justify-center h-full">
          <div
            onDragOver={handleDragOver}
            onDrop={handleDrop}
            className="flex flex-col items-center justify-center h-full w-full xl:w-[1300px] max-w-full rounded-[3rem]"
          >
            <div className="bg-sectionBackground-light dark:bg-transparent sm:px-5 py-6 px-10 rounded-3xl flex flex-col items-center justify-center h-full w-full my-8">
              {contentType !== 'podcast' && (
                <div className="w-full flex flex-col">
                  <div className="relative w-full xsm:text-base text-lg">
                    <div className={`${styles.yourStoryInput} w-full bg-primary-light dark:!bg-[#1C1C1C] border dark:border-borderColor-dark border-borderColor-light ${mediaPreviews.length > 0 ? 'min-h-48 sm:min-h-56' : 'min-h-36 sm:min-h-44'} overflow-auto mt-2 rounded-xl`}>
                      <textarea
                        value={prompt}
                        className={`w-full ${mediaPreviews.length > 0 ? 'sm:h-32 h-[6.5rem]' : 'sm:h-32 h-[5.5rem]'} sm:text-sm minimal-scrollbar overflow-auto py-2 pr-2 pl-4 sm:placeholder:text-sm placeholder:text-[#475467] dark:placeholder:text-[#737373] resize-none outline-none !bg-transparent`}
                        placeholder="Enter the subject of your story here or paste URL link to it or drop images and videos from which Lica should generate videos"
                        onChange={(e) => setPrompt(e.target.value)}
                        id="your-story-input"
                      />
                    </div>
                    <div className="absolute left-4 bottom-0 pb-2 sm:max-w-[70%] max-w-[80%] min-lg:max-w-[90%] overflow-x-auto flex gap-2 max-h-20 minimal-scrollbar-horizontal">
                      {mediaPreviews.length === 0 ? (
                        isUploading && (
                          <div className="rounded-md w-full h-full overflow-hidden px-3 flex items-center text-sm dark:text-secondary-dark text-secondary-light text-opacity-80">
                            <Spinner color="current" />
                            <span className="ml-4">Media is uploading...</span>
                          </div>
                        )
                      ) : (
                        mediaPreviews.map(({ src, type }, index) => (
                          <div key={index} className="relative">
                            {loadingMedia.has(src) ? (
                              <Spinner color="current" />
                            ) : (
                              <>
                                {type === 'image' ? (
                                  <img
                                    draggable={false}
                                    src={src}
                                    alt={`Preview ${index}`}
                                    className="max-w-36 max-h-16 rounded-lg"
                                    onLoad={() => handleMediaLoad(src)}
                                  />
                                ) : (
                                  <video
                                    draggable={false}
                                    src={src}
                                    className="max-w-36 max-h-16 rounded-lg"
                                    onLoadedData={() => handleMediaLoad(src)}
                                  />
                                )}
                                <button
                                  type="button"
                                  onClick={() => handleRemoveMedia(index)}
                                  className="absolute top-0 right-0 bg-white rounded-full p-0.5"
                                >
                                  <Icon icon="carbon:close" style={{ fontSize: '1rem', color: 'black' }} />
                                </button>
                              </>
                            )}
                          </div>
                        ))
                      )}
                    </div>
                    <div className="absolute right-2 bottom-2 p-2 rounded-[10px] bg-sectionBackground-light dark:bg-transparent border border-[#d3d3d3] dark:border-[#404040]">
                      <label htmlFor="file-upload" className={styles.imageUploadIcon}>
                        <Icon
                          icon="tdesign:attach"
                          className="dark:text-secondary-dark text-[#667085]"
                          style={{ fontSize: '1.5rem' }}
                        />
                      </label>
                      <input
                        type="file"
                        id="file-upload"
                        className={styles.imageUploadInput}
                        onChange={handleFileChange}
                        multiple
                        ref={fileInputRef}
                        accept=".jpg,.jpeg,.png,.webp,.tiff,.svg,.mp4,.mov,.avi,.mkv"
                      />
                    </div>
                  </div>
                  <div className="flex mt-3 gap-4 max-md:h-fit h-16 min-lg:h-16 xl:!h-20 text-sm font-medium dark:text-secondary-dark text-[#756A6A] md:hidden">
                    <div
                      className="max-md:w-full w-1/2 min-lg:w-1/3 xl:!w-1/4 h-full flex items-center justify-center text-center py-2 px-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
                      onClick={() => {
                        setPrompt('The evolutionary origins of a dog is an interesting phenomenon. All dogs descend from a species of wolf, but not the gray wolf (Canis lupus), like many people assume. In fact, DNA evidence suggests that the now-extinct wolf ancestor to modern dogs was Eurasian. However, scientists are still working to understand exactly what species gave rise to dogs.  When dogs broke off from their wild ancestors is also a matter of mystery, but genetics suggest that it occurred between 15,000 and 30,000 years ago. While it’s impossible to say exactly how a wild wolf species became a domesticated dog, most scientists believe the process happened gradually as wolves became more comfortable with humans. Perhaps wolves started down this path simply by eating human scraps. Many generations later, humans might have encouraged wolves to stay near by actively feeding them. Later still, those wolves may have been welcomed into the human home and eventually bred to encourage certain traits. All of this is thought to have unfolded over thousands of years...');
                        setSelectedFiles([]);
                        setMediaPreviews([]);
                      }}
                    >
                      Turn Text to Video.
                    </div>
                    <div
                      className="max-md:hidden w-1/2 min-lg:w-1/3 xl:!w-1/4 h-full flex items-center justify-center text-center py-2 px-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
                      onClick={() => {
                        setPrompt('https://www.lonelyplanet.com/articles/ultimate-weekend-barcelona Visually stunning template, young male teenager voice, brief overview of the content, classical jazz music');
                        setSelectedFiles([]);
                        setMediaPreviews([]);
                      }}
                    >
                      Blog Post to Video - Ultimate Long Weekend in Barcelona | Lonely Planet
                    </div>
                    <div
                      className="w-1/3 hidden min-lg:flex xl:w-1/4 h-full items-center justify-center text-center py-2 px-2 rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
                      onClick={() => {
                        setPrompt('https://a.co/d/5nb0wIS Product video for tiktok in a simple minimal template with electronic beats background music');
                        setSelectedFiles([]);
                        setMediaPreviews([]);
                      }}
                    >
                      Amazon Product Page to Video - Revlon Lipstick, Super Lustrous Lipstick
                    </div>
                    <div
                      className="w-1/4 hidden xl:flex py-2 px-2 h-full items-center justify-center text-center rounded-xl dark:bg-[#181818] bg-white border border-[#d3d3d3] dark:border-[#404040] cursor-pointer"
                      onClick={() => {
                        handleClickForReels();
                        setPrompt("Present an inspirational quote with animated text and calming background visuals, like a sunrise or waves. It's to be a reel for instagram in a soft american female voice in pastel gradient color scheme");
                      }}
                    >
                      Photos and Videos to Reels
                    </div>
                  </div>
                </div>
              )}
              {showControl && (
                contentOptions.PAGE.content_types.map((type: string) => {
                  if (type === 'BLOG') {
                    if (contentTypes.includes('page') || contentTypes.includes('blog')) {
                      return (
                        <BlogControls
                          key={type}
                          controls={blogControls}
                          setControls={setBlogControls}
                          showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                        />
                      );
                    }
                  }
                  if (type === 'VIDEO') {
                    if (contentTypes.includes('page') || contentTypes.includes('video')) {
                      return (
                        <VideoControls
                          key={type}
                          controls={videoControls}
                          setControls={setVideoControls}
                          showTitle={contentTypes.includes('page') || contentTypes.length > 1}
                          contentOptions={contentOptions.VIDEO}
                        />
                      );
                    }
                  }
                  if (type === 'PODCAST') {
                    if (contentTypes.includes('page') || contentTypes.includes('podcast')) {
                      return (
                        <PodcastControls
                          key={type}
                          controls={podcastControls}
                          setControls={setPodcastControls}
                          contentOptions={contentOptions.PODCAST}
                        />
                      );
                    }
                  }
                  return null;
                })
              )}
              <Button
                color="primary"
                className="px-4 py-6 mt-10 rounded-xl text-base font-medium text-primary-light dark:text-black hover:dark:bg-secondary-dark bg-tertiary-light dark:bg-secondary-dark"
                onClick={onClick}
                isDisabled={!prompt || prompt.length < 8}
                isLoading={isGenerating}
              >
                Generate Video
              </Button>
            </div>
          </div>
        </div>
      )}
    </LicaPage>
  );
}

export default Generate;

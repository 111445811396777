import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Content({ item, onRendered }: any) {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isLoading, setIsLoading] = useState(true);
  const showingVideos = item.content_type === 'VIDEO';

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.load();
      videoElement.onloadeddata = () => {
        videoElement.style.visibility = 'visible';
        setIsLoading(false);
      };
      videoElement.oncanplay = () => {
        videoElement.style.visibility = 'visible';
        setIsLoading(false);
      };
    }
  }, []);

  const media_url = item.content_type === 'PODCAST' ? item.data.portrait : item.data.media;

  const onClick = () => {
    navigate(`/pages/${item.page_id}`);
  };

  const handleVideoLoaded = () => {
    onRendered();
  };

  const formattedDate = new Date(item.created_at).toLocaleDateString('en-GB', {
    day: '2-digit',
    month: 'short',
    year: 'numeric',
  });

  return (
    <div className={`h-full w-full ${showingVideos ? ` ${item.index !== 0 && '!border-t-2'} !dark:text-secondary-dark text-secondary-light !border-secondary !border-opacity-20` : 'bg-[#F1F5F9] rounded-xl dark:text-primary-dark text-primary-light border border-tertiary border-opacity-10'} flex flex-col`}>
      <div className="relative w-full h-auto rounded-t-xl">
        {isLoading && media_url && (
          <div className="absolute inset-0 flex items-center justify-center bg-gray-200 rounded-t-xl">
            <div className="w-10 h-10 border-4 border-gray-200 border-t-tertiary border-solid rounded-full animate-spin" />
          </div>
        )}
        {media_url && (
          <video
            ref={videoRef}
            preload="metadata"
            className={`w-full h-auto rounded-t-xl ${isLoading ? 'invisible' : 'visible'}`}
            width="100%"
            height="auto"
            controls
            playsInline
            onLoadedData={handleVideoLoaded}
          >
            <source src={media_url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
      <div className={`contentDiv h-fit px-5 py-4 flex flex-col gap-5 text-sm cursor-pointer ${showingVideos && 'sm:!flex-col sm:!h-72 sm:justify-center sm:gap-8 md:justify-center md:gap-8 md:!flex-col md:!h-72 !flex-row items-center justify-between text-start !h-48'}`} onClick={onClick}>
        <p className={`overflow-hidden font-medium ${showingVideos ? 'sm:w-full md:w-full sm:text-center md:text-center text-3xl w-2/6 !font-normal' : 'h-10'}`}>{item.data.title}</p>
        {showingVideos
          && (
            <>
              <h4 className="text-2xl dark:text-secondary-dark text-secondary-light rounded-full flex items-center justify-center">
                {formattedDate}
              </h4>
              <button
                className="bg-tertiary duration-500 font-normal text-xl border dark:text-primary-dark text-primary-light py-1 px-5 rounded-full"
              >
                View More
              </button>
            </>
          )}
        {!showingVideos && (
          <div className="flex gap-2 items-center">
            <img className="w-7 h-7 rounded-full" src="https://freesvg.org/img/abstract-user-flat-4.png" />
            <h4 className="font-medium">{item.user_info?.name}</h4>
          </div>
        )}
      </div>
    </div>
  );
}

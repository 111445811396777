import { Button, Input } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { Icon } from '@iconify/react';

import { isHyperlink } from '@/utils/common';

import styles from './index.module.css';

export default function EditAudioModal({
  onSave, audios, isOpen, onClose, allowTypes,
}: {
  audios: any;
  onSave: (audio: any) => void;
  onClose: () => void;
  isOpen: boolean;
  allowTypes: string[]
}) {
  const [internalAudios, setInternalAudios] = useState(audios);

  useEffect(() => {
    setInternalAudios(audios.filter((_: { type: string; }) => allowTypes.includes(_.type)));
  }, [audios, allowTypes, isOpen]);

  const setAudioItem = (value: string, index: number) => {
    const cloneAudios = [...internalAudios];
    cloneAudios[index] = { ...cloneAudios[index], src: value };
    setInternalAudios(cloneAudios);
  };

  const onUpdate = () => {
    onSave([
      ...audios.filter((_: { type: string; }) => !allowTypes.includes(_.type)),
      ...internalAudios,
    ]);
    onClose();
  };

  return (
    <div>
      {isOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
            {internalAudios && internalAudios.length > 0 ? (
              <>
                <div
                  className="flex flex-col justify-center items-start gap-4"
                  style={{
                    maxHeight: '300px',
                    overflow: 'auto',
                  }}
                >
                  {internalAudios.map((audio: any, index: number) => (
                    <Input
                      key={index}
                      type="text"
                      radius="sm"
                      label={<span className="text-black">Music URL</span>} // Tailwind class for black text
                      placeholder="https://media.com/image"
                      labelPlacement="outside"
                      startContent={<Icon icon="ph:link-bold" />}
                      value={audio.src}
                      isInvalid={!isHyperlink(audio.src)}
                      onValueChange={(value) => setAudioItem(value, index)}
                    />
                  ))}
                </div>
                <div className="w-full flex justify-end items-center gap-1">
                  <Button
                    radius="sm"
                    size="md"
                    color="primary"
                    className="dark:text-primary-dark text-primary-light-color"
                    variant="light"
                    onClick={onClose}
                  >
                    Cancel
                  </Button>
                  <Button
                    size="md"
                    color="primary"
                    className="dark:text-primary-dark text-primary-light bg-tertiary-light dark:bg-tertiary-dark rounded-lg"
                    onClick={onUpdate}
                  >
                    Update Music
                  </Button>
                </div>
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center items-center gap-4">
                  <p>Your content has no audio.</p>
                </div>
                <div className="w-full flex justify-end items-center gap-1">
                  <Button
                    radius="sm"
                    size="md"
                    color="primary"
                    className="dark:text-primary-dark text-primary-light-color"
                    variant="light"
                    onClick={onClose}
                  >
                    Close
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

import {
  ForwardedRef, forwardRef, useEffect, useState,
} from 'react';
import {
  cancelRender,
  continueRender,
  delayRender,
  getRemotionEnvironment,
  Loop,
  OffthreadVideo,
  useVideoConfig,
  Video,
} from 'remotion';
import { getVideoMetadata } from '@remotion/media-utils';

const LoopedOffthreadVideo = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const [duration, setDuration] = useState<null | number>(null);
  const [handle] = useState(() => delayRender());
  const { fps } = useVideoConfig();

  useEffect(() => {
    getVideoMetadata(props.src)
      .then(({ durationInSeconds }) => {
        setDuration(durationInSeconds);
        continueRender(handle);
      })
      .catch(() => {
        cancelRender(handle);
      });
  }, [handle]);

  if (duration === null) {
    return null;
  }

  return (
    <Loop
      durationInFrames={Math.floor(fps * duration)}
      style={props.style}
    >
      <OffthreadVideo
        src={props.src}
        startFrom={props.startFrom}
        transparent={false}
        className="object-cover w-full"
        volume={props.volume || 0}
      />
    </Loop>
  );
});

const RemotionVideo = forwardRef((props: any, ref: ForwardedRef<any>) => {
  const { isPlayer } = getRemotionEnvironment();
  const { style, ...otherProps } = props;

  if (isPlayer) {
    return (
      <div
        style={props.style}
        className="overflow-hidden"
      >
        <Video
          ref={ref}
          src={props.src}
          startFrom={props.startFrom}
          style={{
            width: '100%',
            height: '100%',
            objectFit: props.style?.objectFit || 'cover',
          }}
          loop={props.loop}
          {...otherProps}
        />
      </div>
    );
  }

  // if (props.loop) {
  //   return (
  //     <div
  //       style={props.style}
  //       className="overflow-hidden"
  //     >
  //       <LoopedOffthreadVideo
  //         src={props.src}
  //         startFrom={props.startFrom}
  //         transparent={false}
  //         className="w-full h-full object-cover"
  //         {...otherProps}
  //       />
  //     </div>
  //   );
  // }

  return (
    <div
      style={props.style}
      className="overflow-hidden"
    >
      <OffthreadVideo
        src={props.src}
        startFrom={props.startFrom}
        transparent={false}
        style={{
          width: '100%',
          height: '100%',
          objectFit: props.style?.objectFit || 'cover',
        }}
        {...otherProps}
      />
    </div>
  );
});

RemotionVideo.displayName = 'Video';

export default RemotionVideo;
